import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import { underDevelopment } from "src/helpers/helpers";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${sidebarMenu
        ? "sidebar-menu"
        : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
        } `}
    >
      <nav>
        <ul>
          <li><Link to={"/tours"}>{"Tours"}</Link></li>
          {underDevelopment() &&
            <li><Link to={"/loja"}>{"Loja"}</Link></li>
          }
          <li><Link to={"/sobre"}>{"Sobre nós"}</Link></li>
          <li><Link to={"/contato"}>{"Entre em contato"}</Link></li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

export default multilanguage(NavMenu);
