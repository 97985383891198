import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const MobileNavMenu = ({ strings }) => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li><Link to={"/tours"}>{"Tours"}</Link></li>
        <li><Link to={"/loja"}>{"Loja"}</Link></li>
        <li><Link to={"/sobre"}>{"Sobre nós"}</Link></li>
        <li><Link to={"/contato"}>{"Entre em contato"}</Link></li>
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(MobileNavMenu);
