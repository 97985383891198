import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { multilanguage } from "redux-multilanguage";
import { underDevelopment } from "src/helpers/helpers";
import FooterCopyright from "./FooterCopyright";

const Footer = ({
  spaceClass,
  containerClass,
  sideMenu,
  strings,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer
      className={`footer-area ${spaceClass ? spaceClass : ""}`}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-4 col-sm-6"
              // sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
              }`}
          >
            {/* footer copyright */}
            <FooterCopyright
              footerLogo="/assets/img/logo/logo-1-sm.webp"
              spaceClass="mb-30 items-center"
            />
          </div>
          <div
            className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-6"
              }`}
          >
            <div className="footer-widget mb-30 ml-30 text-center">
              <div className="footer-title">
                <h3 className="uppercase">{"ACESSE"}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>  <Link to={"/tours"}>{"Tours"}</Link></li>
                  {underDevelopment() &&
                    <li>  <Link to={"/loja"}>{"Loja"}</Link></li
                    >}
                  <li>  <Link to={"/sobre"}>{"Sobre nós"}</Link></li>
                  <li>  <Link to={"/contato"}>{"Entre em contato"}</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-6"
              }`}
          >
            <div className="footer-widget mb-30 ml-30 text-center">
              <div className="footer-title">
                <h3 className="uppercase">{"Redes sociais"}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>  <a target="blank" href={"https://www.youtube.com/@CronicasDeViageiro"}>{"YouTube"}</a></li>
                  <li>  <a target="blank" href={"https://www.instagram.com/cronicasdeviageiro"}>{"Instagram"}</a></li>
                  <li>  <a target="blank" href={"https://www.facebook.com/alexcronicasdeviageiro"}>{"Facebook"}</a></li>
                  <li>  <a target="blank" href={"https://apoia.se/cronicasdeviageiro"}>{"Apoia.se"}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

Footer.propTypes = {
  containerClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceClass: PropTypes.string,
  strings: PropTypes.object,
};

export default multilanguage(Footer);
